@import "src/assets/scss/variables.module";

.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 1px solid transparent;
  flex-grow: 0;
  width: auto;
  font-weight: 500;
  border-radius: $border-radius;
}

.inner {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.size {
  &_lg {
    padding: 0 16px;
    font-size: 16px;
    height: 32px;
    line-height: 30px;
  }

  &_xs {
    padding: 0 8px;
    font-size: 12px;
    height: 18px;
    line-height: 14px;
    border-radius: 4px;
  }

  &_md {
    padding: 0 0.75rem;
    font-size: 16px;
    height: 26px;
    line-height: 24px;
    border-radius: 6px;
  }
}

.leftSection {
  margin-right: 0.5rem;
}

.rightSection {
  margin-left: 0.5rem;
}