@import "src/assets/scss/variables.module";

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  //height: calc(100vh - #{$header-height});
}

.navBlock {
  margin: 0.5rem 0;

  &__title {
    padding: 0.25rem 1.5rem 0.25rem 1.5rem;
    text-transform: capitalize;
    color: $secondary;
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.navBottomBlock {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $gray-300;
  padding-top: 0.5rem;
}

.footer {
  display: flex;
  align-items: center;
  height: $footer-height;
  font-size: 0.75rem;
  color: $secondary;
  padding: 0.25rem 1.5rem 0.25rem 1.5rem;
}