.pageHeader {
  &__left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 30px;
  }

  &__right {
    @media (max-width: 575.98px) {
      margin: 1rem 0;
      display: flex;
      justify-content: flex-end;
    }
  }
}