.basic-layout {
  .main-content {
    height: 100vh;
    overflow: auto;
  }

  .page-content {
    height: calc(100% - #{$header-height});
  }

  .account-pages, .basic-pages {
    height: 100%;

    .container {
      height: 100%;
    }
  }
}