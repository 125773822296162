.root {

}

.text {
  display: flex;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}

.opened {

}

.actions {
  margin-top: 0.25rem;
}