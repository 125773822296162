.block {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.controlsBlock {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  flex-wrap: wrap;
}


.imgBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
}

.img {
  width: 100%;
  height: auto;
}