.content {
  p {
    margin-bottom: 0;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */
  ol {
    list-style-type: decimal;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */
  ol ol {
    list-style-type: lower-latin;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */
  ol ol ol {
    list-style-type: lower-roman;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */
  ol ol ol ol {
    list-style-type: upper-latin;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */
  ol ol ol ol ol {
    list-style-type: upper-roman;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */
  ul {
    list-style-type: disc;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */
  ul ul {
    list-style-type: circle;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */
  ul ul ul {
    list-style-type: square;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */
  ul ul ul ul {
    list-style-type: square;
  }
}