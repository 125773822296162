@import "src/assets/scss/variables.module";

.link {
  padding: 0.25rem 1.5rem 0.25rem 1.5rem;
  color: $black;
  font-weight: 500;
  display: block;
  text-transform: capitalize;

  &:hover {
    color: $primary;
  }

  &_active {
    color: $primary;
  }

  .icon {
    display: inline-block;
    line-height: 1.25rem;
    vertical-align: middle;
    margin-right: .5rem;
    font-size: 1.25rem;
  }
}