.block {
  display: flex;

  @media (max-width: 767.98px) {
    flex-direction: column;
  }
}

.avatarWrap {
  margin-right: 1.5rem;

  @media (max-width: 1399.98px) {
  }

  @media (max-width: 991.98px) {
  }

  @media (max-width: 767.98px) {
    display: flex;
    justify-content: center;
    margin: 0 0 1.5rem;
  }

  @media (max-width: 575.98px) {
  }
}