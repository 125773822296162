.root {

}

.topBar {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  @media (max-width: 767.98px) {
    flex-direction: column;
  }
}

.titleBlock {
  flex: 1;

  @media (max-width: 767.98px) {
    order: 2;
  }
}

.actionBar {
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767.98px) {
    order: 1;
  }
}

.subBar {
  margin-top: 0.25rem;
}