.actionButton {
  width: 28px;
  height: 28px;
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;

  &_size {
    &_lg {
      width: 34px;
      height: 34px;
    }

    &_sm {
      width: 24px;
      height: 24px;
    }
  }
}