.img-cover {
  object-fit: cover;
}

.button-items {
  margin-right: -8px;
  margin-bottom: -12px;

  .btn {
    margin-bottom: 12px;
    margin-right: 8px;
  }
}