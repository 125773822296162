@import "src/assets/scss/variables.module";

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 0 !important;
  border-radius: 50%;
  border: 1px solid $secondary;
  color: $secondary;

  &:hover {
    color: $primary;
    border-color: $primary;
  }

  &_size {
    &_lg {
      width: 34px;
      height: 34px;
    }
  }
}