.sidebar-drawer {
  width: $sidebar-width;

  .offcanvas-body {
    padding: 0;
  }

  .sidebar-drawer-header {
    height: $header-height;
  }

  .sidebar-drawer-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{$header-height});
  }
}