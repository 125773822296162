@import "src/assets/scss/variables.module";

.root {

}

.block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: relative;
  font-size: 1.5rem;
  font-weight: 600;
  z-index: 1;

  &.primary {
    svg {
      path {
        fill: rgba(($primary), 0.25);
      }

      text {
        fill: $primary
      }
    }
  }

  &.success {
    svg {
      path {
        fill: rgba(($success), 0.25);
      }

      text {
        fill: $success
      }
    }
  }
}